<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div>
    <b-row>
      <b-col class="d-flex justify-content-between align-items-center">
        <h1 class="font-weight-bolder">
          {{ $t('myProductCatalogs') }}
        </h1>
        <div>
          <b-button :to="registerProductRoute" variant="primary">
            {{ $t('register', { type: $t('product') }) }}
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row class="my-1">
      <b-col xs="12" sm="8">
        <b-overlay :show="categoriesLoading" opacity=".9">
          <b-form-input v-model="searchQuery" autocomplete="off" type="text" />
        </b-overlay>
      </b-col>
      <b-col cols="auto" class="mt-1 mt-sm-0">
        <b-button-group>
          <b-button
            :variant="cardMode ? 'secondary' : 'outline-secondary'"
            @click="setCardMode(true)"
          >
            <feather-icon icon="GridIcon" />
          </b-button>
          <b-button
            :variant="cardMode ? 'outline-secondary' : 'secondary'"
            @click="setCardMode(false)"
          ><feather-icon icon="ListIcon" /></b-button>
        </b-button-group>
      </b-col>
      <b-col v-if="cardMode && !isSmallScreen" cols="auto">
        <b-button-group>
          <b-button variant="outline-secondary" @click="tooggleZoom()">
            <feather-icon :icon="zoom ? 'ZoomOutIcon' : 'ZoomInIcon'" />
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="true" sm="12" class="mb-1 d-flex align-items-end">
        <b-form-group class="mb-0 mr-1" :label="$t('perPage')">
          <b-form-select v-model="productsPerPage" :options="perPageOptions" />
        </b-form-group>
        <b-form-group class="mb-0 mr-2" :label="$t('category')">
          <b-dropdown
            variant="outline-primary"
            left
            :text="selectedCategory === null ? $t('all') : selectedCategory"
          >
            <div class="scrollable">
              <b-dropdown-item
                class="mr-1"
                variant="primary"
                @click="setSelectedCategory(null)"
              >
                {{ $t('all') }}
              </b-dropdown-item>
              <b-dropdown-item
                v-for="category in categories"
                :key="`cat-${category.id}`"
                class="mr-1"
                variant="primary"
                @click="setSelectedCategory(category.name)"
              >
                {{ category.name }}
              </b-dropdown-item>
            </div>
          </b-dropdown>
        </b-form-group>
      </b-col>
      <b-col v-else sm="12">
        <b-button
          pill
          size="sm"
          class="mr-50 mb-50"
          :variant="selectedCategory === null ? 'warning' : 'primary'"
          @click="selectedCategory = null"
        >
          {{ $t('all') }}
        </b-button>
        <b-button
          v-for="category in categories"
          :key="`cat-${category.id}`"
          pill
          size="sm"
          class="mr-50 mb-50"
          :variant="selectedCategory === category.name ? 'warning' : 'primary'"
          @click="selectedCategory = category.name"
        >
          {{ category.name }}
        </b-button>
      </b-col>
    </b-row>
    <b-overlay :show="loading" opacity="1">
      <b-row v-if="cardMode" class="match-height">
        <b-col
          v-for="product in getProdcutsForThisRoute"
          :key="product.id"
          sm="12"
          lg="6"
          :xl="zoom ? '3' : '4'"
          class="mb-2"
        >
          <component
            :is="
              !$route.params.id ? 'products-card' : 'stockable-products-card'
            "
            :product="product"
          />
        </b-col>
      </b-row>
      <b-row v-else class="match-height">
        <b-col
          v-for="product in getProdcutsForThisRoute"
          :key="product.id"
          sm="12"
          class=""
        >
          <component
            :is="!$route.params.id ? 'product-row' : 'stockable-product-row'"
            :product="product"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col class="d-flex">
          <b-pagination
            :value="paginationPage"
            :total-rows="paginationTotalObjects"
            :per-page="paginationPerPage"
            aria-controls="my-table"
            @change="nextPage($event)"
          />
        </b-col>
      </b-row>
    </b-overlay>
    <div
      v-if="getProdcutsForThisRoute.length === 0 && !loading"
      class="py-2 display-4"
    >
      No hay productos 😿
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import _ from 'underscore'
import StockableProductsCard from '@/components/products/StockableProductCard.vue'
import StockableProductRow from '@/components/products/StockableProductRow.vue'
import ProductsCard from '@/components/products/ProductsCard.vue'
import ProductRow from '@/components/products/ProductRow.vue'

export default {
  components: {
    StockableProductsCard,
    ProductsCard,
    StockableProductRow,
    ProductRow,
  },

  data() {
    return {
      searchQuery: null,
      loading: false,
      categoriesLoading: false,
      zoom: false,
      perPageOptions: [
        {
          text: '25',
          value: 25,
        },
        {
          text: '50',
          value: 50,
        },
        {
          text: '100',
          value: 100,
        },
      ],
      productsPerPage: 25,
    }
  },
  computed: {
    ...mapGetters('products', [
      'products',
      'stockableProducts',
      'cardMode',
      'stockableProductsPagination',
      'productsPagination',
      'selectedCategory',
    ]),
    ...mapGetters('categories', ['categories']),
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('app', ['isSmallScreen', 'currentBreakPoint']),
    registerProductRoute() {
      if (this.$route.name === 'productsCatalogs') {
        return { name: 'registerProduct' }
      }
      if (this.$route.name === 'supplierProductsCatalogs') {
        return {
          name: 'registerSupplierProduct',
          params: { id: this.$route.params.id, type: this.$route.params.type },
        }
      }
      return null
    },
    getProdcutsForThisRoute() {
      if (this.$route.name === 'productsCatalogs') {
        return this.products
      }
      if (this.$route.name === 'supplierProductsCatalogs') {
        return this.stockableProducts
      }
      return null
    },
    paginationPage() {
      if (this.$route.name === 'productsCatalogs') {
        return this.productsPagination.page
      }
      if (this.$route.name === 'supplierProductsCatalogs') {
        return this.stockableProductsPagination.page
      }
      return null
    },
    paginationTotalObjects() {
      if (this.$route.name === 'productsCatalogs') {
        return this.productsPagination.total_objects
      }
      if (this.$route.name === 'supplierProductsCatalogs') {
        return this.stockableProductsPagination.total_objects
      }
      return null
    },
    paginationPerPage() {
      if (this.$route.name === 'productsCatalogs') {
        return this.productsPagination.per_page
      }
      if (this.$route.name === 'supplierProductsCatalogs') {
        return this.stockableProductsPagination.per_page
      }
      return null
    },
  },
  watch: {
    searchQuery: _.debounce(function search(query) {
      // this.loading = true
      if (query === '') {
        this.fetchData()
      } else {
        this.filterBy(query)
      }
      // this.loading = false
    }, 400),

    selectedCategory(category) {
      // Deprecated, no longer deleted products
      if (category === 'deleted') {
        this.fetchProducts({
          by_active_status: false,
          meta: {
            pagination: {
              per_page: 100,
            },
          },
        })
      } else {
        // always executes this since category deleted is deprecated
        this.fetchData()
      }
    },
    productsPerPage(perPage) {
      if (this.$route.name === 'productsCatalogs') {
        this.changeProductsPagination({ field: 'per_page', value: perPage })
      }
      if (this.$route.name === 'supplierProductsCatalogs') {
        this.changeStockablePagination({ field: 'per_page', value: perPage })
      }
      this.fetchData()
    },
  },
  created() {
    this.categoriesLoading = true
    this.setSelectedCategory(null)
    this.fetchCategories().then(() => {
      this.categoriesLoading = false
    })
    this.fetchData()
  },
  destroyed() {
    this.clearProducts()
  },
  methods: {
    ...mapMutations('products', {
      setCardMode: 'SET_CARD_MODE',
      clearProducts: 'CLEAR_PRODUCTS',
      changeProductsPagination: 'CHANGE_PRODUCTS_PAGINATION',
      changeStockablePagination: 'CHANGE_STOCKABLE_PAGINATION',
      setSelectedCategory: 'SET_SELECTED_CATEGORY',
    }),
    ...mapActions('products', ['fetchProducts', 'getStockableProducts']),
    ...mapActions('categories', ['fetchCategories']),
    fetchData() {
      this.loading = true
      if (this.$route.name === 'productsCatalogs') {
        this.fetchProducts({
          by_active_status: true,
          by_category: this.selectedCategory ? this.selectedCategory : null,
          by_name: this.searchQuery ? this.searchQuery : null,
          meta: {
            pagination: this.productsPagination,
          },
        }).then(() => {
          this.loading = false
        })
      } else if (this.$route.name === 'supplierProductsCatalogs') {
        let type = ''
        if (this.$route.params.type === 'proveedor') {
          type = 'Supplier'
        }
        if (this.$route.params.type === 'sucursal') {
          type = 'SupplierBranch'
        }
        this.getStockableProducts({
          params: {
            by_category: this.selectedCategory ? this.selectedCategory : null,
            by_name: this.searchQuery ? this.searchQuery : null,
            by_active_status: true,
            meta: {
              pagination: this.stockableProductsPagination,
            },
          },
          stockable: {
            id: this.$route.params.id,
            type,
          },
        }).then(() => {
          this.loading = false
        })
      }
    },
    filterBy() {
      this.fetchData()
    },

    tooggleZoom() {
      this.zoom = !this.zoom
    },

    nextPage(value) {
      if (this.$route.name === 'productsCatalogs') {
        this.changeProductsPagination({ field: 'page', value })
        this.fetchData()
      }
      if (this.$route.name === 'supplierProductsCatalogs') {
        this.changeStockablePagination({ field: 'page', value })
        this.fetchData()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-padding {
  padding-bottom: 7px;
}

.scrollable {
  max-height: 300px !important; /* Ajusta el valor a la altura deseada */
  overflow-y: auto;
}
</style>
