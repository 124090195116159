<template>
  <b-card class="mb-05">
    <b-dropdown
      id="dropdown-1"
      toggle-class="my-custom-toggle"
      size="sm"
      right
      no-caret
      variant="outline-none"
      class="z-top"
    >
      <template #button-content>
        <feather-icon
          icon="MoreVerticalIcon"
          size="18"
          color="#000"
        />
      </template>
      <b-dropdown-item v-b-modal="`product-${product.id}`">
        <span>
          {{ $t('updatePrice') }}
        </span>
        <update-price :product="product" />
      </b-dropdown-item>
      <b-dropdown-item v-b-modal="`confirmDelete-${product.id}`">
        <span class="text-danger">
          {{ $t('delete') }}
        </span>
      </b-dropdown-item>
    </b-dropdown>
    <b-row>
      <b-col class="d-flex">
        <div class="pr-1">
          <b-img
            width="90px"
            :src="`${product.product_catalog.logo}`"
          />
        </div>
        <div>
          <h4 class="mb-0 mt-1">
            {{ product.product_catalog.name }}
          </h4>
          <div class="d-flex flex-column">
            <span
              class="text-muted text-small mb-0"
            >{{ product.product_catalog.sku }} -
              <span class="font-weight-bolder text-dark">
                {{ product.product_catalog.brand || 'N/A' }}
              </span>
            </span>
            <div>
              <b-badge
                pill
                variant="primary"
                class="text-small"
              >
                {{ product.product_catalog.category.name }}
              </b-badge>
            </div>
          </div>
        </div>
      </b-col>
      <b-col class="d-none d-md-block">
        <div class="px-2">
          <h5 class="mt-1 text-small">
            {{ $t('description') }}
          </h5>
          <div class="text-justify text-muted">
            {{ product.product_catalog.description }}
          </div>
        </div>
      </b-col>
      <b-col cols="auto">
        <div class="d-flex flex-column align-items-end px-1">
          <h5 class="mt-1 text-small">
            {{ $t('unitPrice') }}
          </h5>
          <div class="d-flex align-items-center">
            <h4 class="text-success mr-1">
              $ {{ product.price_list | money }}
            </h4>
            <h6>S/IVA</h6>
          </div>
        </div>
      </b-col>
    </b-row>

    <confirmation-modal
      :id="`confirmDelete-${product.id}`"
      :variant="'danger'"
      @confirm="deleteProduct(product.id)"
    >
      <template v-slot:content>
        {{ $t('deleteProductConfirmation') }}
      </template>
    </confirmation-modal>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import UpdatePrice from '@/@core/components/UpdatePriceModal.vue'
import ConfirmationModal from '../ConfirmationModal.vue'

export default {
  components: {
    BCard,
    UpdatePrice,
    ConfirmationModal,
  },
  props: {
    product: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
  },

  computed: {
    ...mapGetters('auth', ['currentUser']),
  },
  methods: {
    ...mapActions('products', [
      'deleteStockableProduct',
      'getStockableProducts',
    ]),

    deleteProduct(stockableId) {
      this.deleteStockableProduct(stockableId).then(() => {
        let type = ''
        if (this.$route.params.type === 'proveedor') {
          type = 'Supplier'
        }
        if (this.$route.params.type === 'sucursal') {
          type = 'SupplierBranch'
        }
        this.getStockableProducts({
          params: {
            meta: {
              pagination: { all: true },
            },
          },
          stockable: {
            id: this.$route.params.id,
            type,
          },
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.bg-best-price {
  background-color: #77c77b;
}
.mb-05 {
  margin-bottom: 0.5rem;
}
.text-small {
  font-size: 10px;
}
.hover-candy:hover {
  transform: translateY(-5px);
}
#dropdown-1 {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
