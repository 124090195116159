<template>
  <b-card class="mb-0">
    <b-dropdown
      id="dropdown-1"
      toggle-class="my-custom-toggle"
      size="sm"
      right
      no-caret
      variant="outline-none"
    >
      <template #button-content>
        <feather-icon
          icon="MoreVerticalIcon"
          size="18"
          color="#000"
        />
      </template>
      <b-dropdown-item
        v-if="currentUser.role_name === 'admin'"
        :to="{ name: 'editProduct', params: { productId: product.id } }"
      >{{ $t('edit') }}</b-dropdown-item>
      <div v-if="currentUser.role_name === 'admin'">
        <b-dropdown-item
          v-if="product.active_status"
          v-b-modal="`confirmDelete-${product.id}`"
        >
          <span class="text-danger">
            {{ $t('delete') }}
          </span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="!product.active_status"
          v-b-modal="`confirmReactivate-${product.id}`"
        >
          <span class="text-success">
            {{ $t('reactivate') }}
          </span>
        </b-dropdown-item>
      </div>
    </b-dropdown>
    <b-row>
      <b-col
        cols="auto"
        class="pr-0"
      >
        <b-img
          width="100"
          rounded=""
          :src="`${product.logo}`"
        />
      </b-col>
      <b-col>
        <h3 class="mb-0">
          {{ product.name || product.name }}
        </h3>
        <span class="text-muted text-small h5">{{ product.sku }} -
          <span class="font-weight-bolder text-dark">
            {{ product.brand || 'N/A' }}
          </span>
        </span>
        <div>
          <b-badge
            pill
            variant="primary"
            class="text-small"
          >
            {{ product.category.name }}
          </b-badge>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col class="border-top pt-1">
        <p>
          {{ product.description }}
        </p>
      </b-col>
    </b-row>
    <confirmation-modal
      :id="`confirmDelete-${product.id}`"
      :variant="'danger'"
      @confirm="deleteProduct()"
    >
      <template v-slot:content>
        {{ $t('deleteProductConfirmation') }}
      </template>
    </confirmation-modal>
    <confirmation-modal
      :id="`confirmReactivate-${product.id}`"
      :variant="'success'"
      @confirm="reactivateProduct()"
    >
      <template v-slot:content>
        {{ $t('reactivateProductConfirmation') }}
      </template>
    </confirmation-modal>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ConfirmationModal from '@/components/ConfirmationModal.vue'

export default {
  components: {
    ConfirmationModal,
  },
  props: {
    product: {
      type: Object,
      default: () => ({
        name: '',
        category: '',
        categories_ids: [],
        img: '',
        price: 0,
        cost: 0,
        variant: '',
      }),
    },
    index: {
      type: Number,
      default() {
        return null
      },
    },
    isPos: {
      type: Boolean,
      default() {
        return false
      },
    },
    isInCart: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  data() {
    return {
      isModalVisible: false,
      selectedProduct: {},
    }
  },
  computed: {
    ...mapGetters('auth', ['currentUser']),
  },
  mounted() {},
  methods: {
    ...mapActions('products', ['deactivateProduct', 'fetchProducts']),

    deleteProduct() {
      this.deactivateProduct(this.product.id).then(() => {
        this.fetchProducts({
          by_active_status: true,
          meta: {
            pagination: {
              per_page: 100,
            },
          },
        })
      })
    },
    reactivateProduct() {
      this.deactivateProduct(this.product.id).then(() => {
        this.fetchProducts({
          by_active_status: false,
          meta: {
            pagination: {
              per_page: 100,
            },
          },
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#dropdown-1 {
  position: absolute;
  top: 0;
  right: 0;
}
.text-small {
  font-size: 10px;
}
</style>
