<template>
  <b-card class="mb-0">
    <b-dropdown
      id="dropdown-1"
      toggle-class="my-custom-toggle"
      size="sm"
      right
      no-caret
      variant="outline-none"
    >
      <template #button-content>
        <feather-icon
          icon="MoreVerticalIcon"
          size="18"
          color="#000"
        />
      </template>
      <!-- <b-dropdown-item
        v-if="currentUser.role_name === 'admin'"
        :to="{ name: 'editProduct', params: { productId: product.id } }"
      >
        {{ $t('edit') }}
      </b-dropdown-item> -->
      <b-dropdown-item v-b-modal="`product-${product.id}`">
        <span>
          {{ $t('options') }}
        </span>
        <update-price :product="product" />
      </b-dropdown-item>
      <b-dropdown-item v-b-modal="`confirmDelete-${product.id}`">
        <span class="text-danger">
          {{ $t('delete') }}
        </span>
      </b-dropdown-item>
    </b-dropdown>
    <b-row>
      <b-col
        cols="auto"
        class="pr-0"
      >
        <b-img
          width="100"
          rounded=""
          :src="`${product.product_catalog.logo}`"
        />
      </b-col>
      <b-col>
        <div class="d-flex align-items-center">
          <h3 class="mb-0 mr-1">
            {{ product.product_catalog.name || product.name }}
            <!-- {{ product.product_catalog.brand || product.brand }} -->
          </h3>
          <feather-icon
            v-if="product.is_special_delivery"
            :id="`tooltip-delivery-${product.id}`"
            icon="TruckIcon"
            size="18"
            color="#28c76f"
          /><b-tooltip
            :target="`tooltip-delivery-${product.id}`"
            triggers="hover"
            boundary-padding="10"
          >
            <span>
              {{ product.special_delivery_description }}
            </span>
          </b-tooltip>
        </div>
        <span
          class="text-muted text-small h5"
        >{{ product.product_catalog.sku }} -
          <span class="font-weight-bolder text-dark">
            {{ product.product_catalog.brand || 'N/A' }}
          </span>
        </span>
        <div>
          <b-badge
            pill
            variant="primary"
            class="text-small"
          >
            {{ product.product_catalog.category.name }}
          </b-badge>
        </div>
        <div class="d-flex mt-1">
          <h4 class="text-primary">
            <span class="text-success mt-1">
              ${{ product.price_list | money }}
            </span>
            / {{ $tc(unitsFilter(product.product_catalog.unit_type)) }}
            <span class="mr-1 text-small">
              S/IVA
            </span>
          </h4>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col class="border-top pt-1">
        <p>
          {{ product.product_catalog.description }}
        </p>
      </b-col>
    </b-row>
    <confirmation-modal
      :id="`confirmDelete-${product.id}`"
      :variant="'danger'"
      @confirm="deleteProduct(product.id)"
    >
      <template v-slot:content>
        {{ $t('deleteProductConfirmation') }}
      </template>
    </confirmation-modal>
  </b-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import UpdatePrice from '@/@core/components/UpdatePriceModal.vue'
import ConfirmationModal from '@/components/ConfirmationModal.vue'

export default {
  components: {
    UpdatePrice,
    ConfirmationModal,
  },
  props: {
    product: {
      type: Object,
      default: () => ({
        name: '',
        category: '',
        categories_ids: [],
        img: '',
        price: 0,
        cost: 0,
        variant: '',
      }),
    },
    index: {
      type: Number,
      default() {
        return null
      },
    },
    isPos: {
      type: Boolean,
      default() {
        return false
      },
    },
    isInCart: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  data() {
    return {
      isModalVisible: false,
      selectedProduct: {},
    }
  },
  computed: {
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('pos', ['posItems']),
    ...mapGetters('app', ['unitsFilter']),
  },
  mounted() {},
  methods: {
    ...mapMutations('pos', {
      addProduct: 'ADD_ITEM_TO_POS',
      removeProduct: 'REMOVE_ITEM_FROM_POS',
      changeQuantity: 'CHANGE_ITEM_QUANTITY',
    }),

    ...mapActions('products', [
      'getStockableProducts',
      'deleteStockableProduct',
    ]),

    add() {
      if (
        this.$route.name === 'cart'
        || this.$route.name === 'registerSupplierProduct'
      ) {
        if (
          !this.posItems.find(
            item => item.id === this.product.product_catalog.id,
          )
        ) {
          this.addProduct(JSON.parse(JSON.stringify(this.product)))
        }
      }
    },
    remove(index) {
      if (
        this.$route.name === 'cart'
        || this.$route.name === 'registerSupplierProduct'
      ) {
        this.removeProduct({ index })
      }
    },

    deleteProduct(stockableId) {
      this.deleteStockableProduct(stockableId).then(() => {
        let type = ''
        if (this.$route.params.type === 'proveedor') {
          type = 'Supplier'
        }
        if (this.$route.params.type === 'sucursal') {
          type = 'SupplierBranch'
        }
        this.getStockableProducts({
          params: {
            meta: {
              pagination: { all: true },
            },
          },
          stockable: {
            id: this.$route.params.id,
            type,
          },
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#dropdown-1 {
  position: absolute;
  top: 0;
  right: 0;
}
.text-small {
  font-size: 10px;
}
</style>
