<template>
  <b-modal
    :ref="`product-${product.id}`"
    :id="`product-${product.id}`"
    hide-footer
  >
    <b-form-group
      label="Nuevo precio"
      class="d-flex flex-column"
    >
      <b-form-input
        v-model="updateProduct.price_list"
        class="mb-1"
      />
      <b-button
        variant="primary"
        class="btn-tour-skip mr-1"
        @click="updateAndReloadData"
      >
        <span class="mr-25 align-middle">{{ $t('save') }}</span>
      </b-button>
    </b-form-group>
    <b-form-group
      :label="$t('specialDelivery')"
      class="d-flex flex-column"
    >
      <b-form-checkbox
        v-model="updateProduct.is_special_delivery"
        name="check-button"
        switch
        class="mb-1"
        variant="success"
      />
    </b-form-group>
    <b-form-group
      v-if="updateProduct.is_special_delivery"
      :label="$t('specialDeliveryDescription')"
      class="d-flex flex-column"
    >
      <b-form-input
        v-model="updateProduct.special_delivery_description"
        class="mb-1"
      />
      <b-button
        :disabled="
          !(
            updateProduct.is_special_delivery &&
            updateProduct.special_delivery_description
          )
        "
        variant="primary"
        class="btn-tour-skip mr-1 text-right"
        @click="updateDeliveryInfo"
      >
        <span class="mr-25 align-middle">{{ $t('save') }}</span>
      </b-button>
    </b-form-group>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    product: {
      type: Object,
      default: () => ({
        id: '',
      }),
    },
  },
  data() {
    return {
      updateProduct: {},
    }
  },
  computed: {
    ...mapGetters('products', ['selectedCategory']),
  },
  mounted() {
    this.updateProduct = { ...this.product }
  },
  methods: {
    ...mapActions('products', ['getStockableProducts']),
    ...mapActions('products', ['updateStockableProductPrice', 'updateStockableSpecialDelivery']),
    updateAndReloadData() {
      this.updateStockableProductPrice({
        id: this.product.id,
        price_list: this.updateProduct.price_list,
      }).then(() => {
        this.$refs[`product-${this.product.id}`].hide()
        let type = ''
        if (this.$route.params.type === 'proveedor') {
          type = 'Supplier'
        }
        if (this.$route.params.type === 'sucursal') {
          type = 'SupplierBranch'
        }
        this.getStockableProducts({
          params: {
            by_category: this.selectedCategory ? this.selectedCategory : null,
            meta: {
              pagination: { all: true },
            },
          },
          stockable: {
            id: this.$route.params.id,
            type,
          },
        })
      })
    },
    updateDeliveryInfo() {
      this.updateStockableSpecialDelivery({
        id: this.product.id,
        is_special_delivery: this.updateProduct.is_special_delivery,
        special_delivery_description: this.updateProduct.special_delivery_description,
      }).then(() => {
        this.$refs[`product-${this.product.id}`].hide()
        let type = ''
        if (this.$route.params.type === 'proveedor') {
          type = 'Supplier'
        }
        if (this.$route.params.type === 'sucursal') {
          type = 'SupplierBranch'
        }
        this.getStockableProducts({
          params: {
            by_category: this.selectedCategory ? this.selectedCategory : null,
            meta: {
              pagination: { all: true },
            },
          },
          stockable: {
            id: this.$route.params.id,
            type,
          },
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
